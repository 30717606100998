.team-2{
    .card{
        .card-title{
            margin: 10px 0 0;
        }
        h6{
            margin-top: 5px;
        }
    }

    // [class*="col-md-"]:nth-child(2){
    //     margin-top: -50px;
    // }
}

.team-3{
  .card.card-profile {
    .card-image a > img {
        border-radius: 0;
        border-bottom-left-radius: $border-radius-sm;
        border-top-left-radius: $border-radius-sm;
    }

    .card-title {
        margin-top: 15px;
    }

    &[data-background="full"] {
      .card-body {
        z-index: 1;
      }
    }
  }
}


.team-4{
    .card{
        text-align: left;

        .footer{
            margin-top: 0;
        }
    }
}

.team-5 {
    .card {
      background-color: linear-gradient(0deg, black, black, #3933fc);
    }
    h5.description{
        margin-bottom: 100px;
    }

    [class*="col-md-"]{
        margin-top: 50px;

        // &:nth-child(2){
        //     margin-top: 95px;
        // }
    }

    .card.card-profile{
        .card-title + .category{
            margin-bottom: .625rem;
        }
    }
}

.carousel-team {
  margin-top: 100px;

  [class*="col-md-"]{
    padding: 0;
  }

  .carousel-inner {
    height: $card-background-height-lg;

    .title {
      text-align: right;
      font-size: 3.8rem;
      margin: 70px;
    }

    img {
      width: 100%;
      border-radius: 0;
    }

    .wrapper {
      padding: 15%;

      .category {
        text-align: right;
        padding-right: 30px;
        font-size: .9rem;
        text-transform: uppercase;
      }

      .description {
        padding: 30px;
        text-align: justify;
      }

      .footer {
        border: none;
        background: none;
      }
    }
  }

  .carousel-control-prev,
  .carousel-control-next {
    top: auto;
    left: 15%;
    bottom: 8%;
    opacity: 1;
    width: 0%;
    cursor: pointer;
  }

  .carousel-control-next {
    left: 20%;

  }
}

@media screen and (max-width:991px){

  .team-1{
    .carousel-team .wrapper {
      margin-top: 0px;
    }

    .carousel-team .carousel-inner .wrapper .description{
      padding: 15px;
    }
  }
  .team-3 .card-profile[data-background=full] {
    height: 550px;
  }

  .team-5{
    .card{
      .card-footer{
        text-align: center;
        div{
          float: none !important;
        }
      }
    }
  }
}

@media screen and (max-width:768px){
  .team-1{
    .carousel-team .carousel-control-next, .carousel-team .carousel-control-prev{
      top: 96%;
    }
  }

  .team-2{
    .card-image{
      img{
        width: 100%;
      }
    }
  }

  .team-3 .card-profile[data-background=full] {
    height: auto;
      a{
        height: 100%;
      }
  }
}

@media screen and (max-width:576px){


    .team-1 .carousel-team .wrapper {
    margin-top: 15px;
}
  }
